<template>
  <div>
    <h1>
      <router-link to="/mv">
        {{ str }} <i class="iconfont icon-jiantou1"></i>
      </router-link>
    </h1>
    <ul class="mv-list">
      <li v-for="(item) in mv"
          :key="item.id">
        <div class="mv-img-wrap"
             @click="toMvDetail(item.id)">
          <img v-lazy="item.picUrl">
          <p class="iconfont icon-play play"></p>
          <p class="play-count iconfont icon-play">{{item.playCount}}</p>
        </div>
        <div class="mv-info">
          <p class="title">{{item.name}}</p>
          <p class="author">{{item.artistName}}</p>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'mv',
  props: {
    mv: {
      type: Array,
      default () {
        return []
      }
    },
    str: String
  },
  methods: {
    toMvDetail (id) {
      this.$router.push(`/mvdetail?id=${id}`)
    }
  }
}
</script>

<style lang="less" scoped>
h1 {
  margin: 30px 0 10px 20px;
  font-size: 20px;
}
.mv-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  li {
    width: 40%;
    padding-right: 15px;
    .mv-img-wrap {
      position: relative;
      cursor: pointer;
      margin: 10px 0;
      img {
        width: 100%;
        border-radius: 15px;
        max-height: 280px;
      }
      .play-count {
        position: absolute;
        top: 5px;
        right: 10px;
        color: #fff;
        text-shadow: 0 0 2px rgb(0, 0, 0);
      }
      .play-count::before {
        margin-right: 10px;
      }
      .play::before {
        content: '\e665';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 35px;
        height: 35px;
        background-color: rgba(255, 255, 255, 0.8);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ff0000;
        opacity: 0;
        transition: 0.3s;
        cursor: pointer;
      }
    }
    .mv-img-wrap:hover .play::before {
      opacity: 1;
    }
    .mv-info {
      p {
        margin: 8px 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .author {
        color: #a5a1a1;
        font-size: 14px;
        margin-top: 5px;
      }
    }
  }
}
@media screen and (max-width: 479px) {
  h1 {
    font-size: 14px;
    margin: 10px 0 5px 20px;
  }
  h1 i {
    font-size: 12px;
  }
  .mv-list li .mv-info p {
    font-size: 12px;
    margin-top: 5px;
  }
  .mv-img-wrap p {
    display: none;
  }
  .mv-list li {
    padding: 0;
    width: 38vw;
  }
  .author {
    font-size: 12px !important;
  }
  .mv-list li .mv-info p {
    margin: 0;
  }
  .mv-list li .mv-img-wrap {
    margin-bottom: 1vw;
  }
}
</style>
