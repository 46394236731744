<template>
  <el-carousel :interval="3000"
               :type="swiperStyle"
               :height="imgHeight+'px'"
               arrow='hover'
               class="carousel">
    <el-carousel-item v-for="(item, index) in banners"
                      :key="index">
      <span class="title"
            :style="{backgroundColor: item.titleColor}">
        {{ item.typeTitle }}
      </span>
      <a :href="item.url"
         target="_blank">
        <img :src="item.imageUrl">
      </a>
    </el-carousel-item>
  </el-carousel>
</template>

<script>
export default {
  data () {
    return {
      imgHeight: 250,
      swiperStyle: 'card'
    }
  },
  props: {
    banners: {
      type: Array,
      default () {
        return []
      }
    }
  },
  mounted () {
    if (document.documentElement.clientWidth < 600) {
      this.imgHeight = 140
      this.swiperStyle = 'string'
    }
  }
}
</script>
<style scoped>
.el-carousel {
  overflow: hidden;
  margin: 10px 0 30px;
}
.el-carousel__item {
  height: 100%;
  overflow: hidden;
}
.el-carousel__item a img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
}
.title {
  position: absolute;
  right: 0;
  bottom: 0;
  font-size: 12px;
  color: #fff;
  height: 10px;
  line-height: 10px;
  padding: 5px 10px;
  background-color: red;
  text-align: center;
  border-radius: 10px 0 10px 0;
}
.carousel >>> .el-carousel__indicator--horizontal {
  border: none !important;
  margin: 10px 5px 5px 10px;
}
.carousel >>> .el-carousel__button {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.carousel >>> .el-carousel__indicator.is-active button {
  background-color: rgb(236, 65, 65);
}
@media screen and (max-width: 479px) {
  .el-carousel {
    width: 84vw;
    margin-bottom: 15vw;
  }
  .carousel >>> .el-carousel__indicator--horizontal {
    display: none;
  }
  .carousel >>> .el-carousel__button {
    display: none;
  }

  .carousel >>> .el-carousel__indicator.is-active button {
    display: none;
  }
}
</style>
