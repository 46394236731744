<template>
  <div>
    <h3>
      <router-link to="/songs"> 最新音乐 <i class="iconfont icon-jiantou1"></i> </router-link>
    </h3>

    <div class="newSong">
      <div class="rsitem"
           v-for="(item, index) in newSongsList"
           :key="item.id"
           @click="play(item)"
           @mousemove="isShow = index"
           @mouseleave="isShow = false">
        <img v-lazy="item.picUrl">
        <div class="info">
          <div class="new_name">{{ item.name }}</div>
          <div class="new_author">{{ item.song.artists[0].name }}</div>
        </div>
        <i v-show="isShow === index"
           class="iconfont play_icon icon-bofang"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    newSongs: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      isShow: false,
      newSongsList: []
    }
  },
  computed: {
    musicQueue () {
      return this.$store.state.musicQueue
    }
  },
  watch: {
    newSongs: function (newVal, oldVal) {
      if (document.documentElement.clientWidth < 600) {
        this.newSongsList = newVal.slice(0, 6)
      } else {
        this.newSongsList = newVal
      }
    }
  },
  methods: {
    play (item) {
      const duration = this.$options.filters.formatDuration(item.song.duration)
      const musicInfo = {
        imgUrl: item.picUrl,
        artistInfo: item.song.artists,
        songName: item.name,
        id: item.id,
        duration
      }
      this.playMusic(item, musicInfo)
    }
  }
}
</script>

<style scpoed lang="less">
.newSong div:hover {
  background-color: #f1f1f1;
}

h3 {
  margin: 40px 0 10px 20px;
  font-size: 20px;
  cursor: pointer;
}
.newSong {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  overflow: hidden;
  justify-content: space-evenly;
}
.rsitem {
  position: relative;
  width: 28%;
  overflow: hidden;
  display: flex;
  margin-top: 20px;
  .info {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    justify-content: space-evenly;
    .new_name {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .new_author {
      font-size: 16px;
      color: gray;
    }
  }
  .play_icon {
    position: absolute;
    top: 40px;
    left: 40px;
    font-size: 30px;
    color: #ff0000;
    cursor: pointer;
  }
  img {
    vertical-align: middle;
    width: 120px;
    border-radius: 10px;
    cursor: pointer;
  }
}

@media screen and (max-width: 479px) {
  div h3 {
    margin: 3vw 0 10px 20px;
  }
  div h3 a {
    font-size: 14px;
  }
  div h3 i {
    font-size: 12px !important;
  }
  .rsitem {
    width: 38vw;
    margin: 2vw 1vw;
  }
  .rsitem img {
    width: 18vw;
  }
  .rsitem .info {
    font-size: 14px;
    width: 100%;
    margin-left: 1.5vw;
  }
  .rsitem .info .new_author {
    font-size: 8px;
  }
  .icon-play:before {
    display: none;
  }
}
</style>
