<template>
  <div class='individuation'>
    <individuation-swiper :banners='banners' />
    <recommend-song :recommend-songs="recommendSongs"></recommend-song>
    <mv :str="'独家放送'"
        :mv="privateContent" />
    <newSong :new-songs="newSongs"></newSong>
    <mv :str="'推荐MV'"
        :mv="mv" />
    <div class="blank"></div>
  </div>
</template>

<script>
import {
  recommendSonglistAPI,
  bannerAPI,
  privatecontentAPI,
  recommendSongAPI,
  recommendMVAPI
} from '@/api/api'
import individuationSwiper from './children/IndividuationSwiper'
import RecommendSong from './children/RecommendSong'
import mv from './children/Mv'
import newSong from './children/NewSong'

export default {
  components: {
    individuationSwiper,
    RecommendSong,
    newSong,
    mv
  },
  data () {
    return {
      banners: [],
      recommendSongs: [],
      privateContent: [],
      newSongs: [],
      mv: []
    }
  },
  async created () {
    const { data: res } = await bannerAPI()
    this.banners = res.banners
    const { data: response } = await recommendSonglistAPI({ limit: 12 })
    this.recommendSongs = response.result
    const { data: privateContent } = await privatecontentAPI()
    this.privateContent = privateContent.result.slice(0, 2)
    const { data: newSong } = await recommendSongAPI()
    this.newSongs = newSong.result.slice(0, 9)
    const { data: mv } = await recommendMVAPI()
    this.mv = mv.result

    if (res.code !== 200) {
      return this.$message.error({
        message: '获取数据出了点问题嗷',
        center: true,
        showClose: true
      })
    }
  }
}
</script>

<style scoped lang="less">
.individuation {
  width: 100%;
  height: 100%;
}
.box {
  width: 100px;
  height: 100px;
  margin: 0 200px;
}
.blank {
  height: 10px;
}
</style>
